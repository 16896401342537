/* eslint-disable prettier/prettier */
<template>
  <div>
    <b-row class="mb-5">
      <b-col>
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          <b>Data Organisasi</b>
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button size="sm" id="black-btn" class="black-btn" @click="cetakPDF()">
          <v-icon style="color:white;" small>mdi-file-pdf-box</v-icon>
          Cetak PDF
        </b-button>
      </b-col>
    </b-row>
    <div>
      <div>
        <b-tabs pills card justified v-model="activeTab" class="shadow" style="border-radius:10px;">
          
          <b-tab active title="Profil" style="background-color:white;margin-top:0px;">
            <section class="d-flex justify-content-center align-items-center" v-if="loading1">
              <b-spinner type="border" size="lg"></b-spinner>
            </section>
            <b-row v-if="!loading1">
              <b-col cols="7">
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">{{ "Nama Ormas" }}</p></b-col
                  >:<b-col sm="6">{{ info_organization.nama_ormas}}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">{{ "Singkatan" }}</p></b-col
                  >:<b-col sm="6">{{ info_organization.nama_singkat}}</b-col>
                </b-row>
                <b-row class="pt-0 pb-3 d-flex align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Nomor SKT Aktif" }}</p></b-col
                  >:<b-col sm="6" >{{ info_organization.no_skt}}
                    <b-button v-if="info_organization.file_skt != null" @click="newTab(info_organization.file_skt)" size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">
                      {{ "Status Sekretariat" }}
                    </p></b-col
                  >:<b-col sm="6" v-if="info_organization.masterLokasiSekretariat">{{ info_organization.masterLokasiSekretariat.nama_lokasi }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">
                      {{ "Alamat sekretariat" }}
                    </p></b-col
                  >:<b-col sm="6">{{ info_organization.alamat_sekretariat }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">{{ "Kabupaten/Kota" }}</p></b-col
                  >:<b-col sm="6">{{ info_organization.masterKabupatenKota.nama }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">{{ "Propinsi" }}</p></b-col
                  >:<b-col sm="6">{{ info_organization.masterProvinsi.nama }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">{{ "Nomor Telepon" }}</p></b-col
                  >:<b-col sm="6">{{ info_organization.no_telp }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">{{ "Nomor FAX" }}</p></b-col
                  >:<b-col sm="6">{{ info_organization.no_fax }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">{{ "Email" }}</p></b-col
                  >:<b-col sm="6">{{ info_organization.email }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">
                      {{ "Tujuan organisasi" }}
                    </p></b-col
                  >:
                  <b-col sm="6">
                      <ul class="ml-5">
                        <li v-for="(item,index) in info_organization.tujuanOrmas" :key="index">{{ item.keterangan }}</li>
                      </ul>
                    </b-col>
                </b-row>
                
                <b-row class="d-flex align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "NPWP" }}</p></b-col
                  >:<b-col sm="6">{{ info_organization.npwp }} <b-button v-if="info_organization.file_npwp != null" @click="newTab(info_organization.file_npwp)" size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button></b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">{{ "Ciri khusus" }}</p></b-col
                  >:<b-col sm="6">{{ info_organization.masterCiriKhusus.nama_ciri_khusus }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">{{ "Bidang Kegiatan" }}</p></b-col
                  >:<b-col sm="6"><ul class="ml-5">
                        <li v-for="(item,index) in info_organization.bidangKegiatans" :key="index">{{ item.masterJenisOrmas.nama_jenis_ormas }}</li>
                      </ul></b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">{{ "Wadah Berhimpun" }}</p></b-col
                  >:<b-col sm="6">
                    <ul class="ml-5" v-if="info_organization.berhimpunOtonom.length > 0">
                      <span v-for="(item,index) in info_organization.berhimpunOtonom" :key="index">
                      <li v-if="item.jenis == 1">{{ item.afiliasiOrmas.nama_ormas }}</li>
                      </span>
                    </ul>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="3"
                    ><p style="font-weight: 600">{{ "Badan Otonom / Sayap Organisasi" }}</p></b-col
                  >:<b-col sm="6">
                    <ul class="ml-5" v-if="info_organization.berhimpunOtonom.length > 0">
                      <span v-for="(item,index) in info_organization.berhimpunOtonom" :key="index">
                      <li v-if="item.jenis == 2">{{ item.afiliasiOrmas.nama_ormas }}</li>
                      </span>
                    </ul>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="5">
                <b-row>
                  <img v-if="info_organization.file_logo !== null" :src="fileURL + '/' + info_organization.file_logo"
                    style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                    alt="abstract"
                    @error="replaceByDefault"
                    />
                  <img
                    v-if="info_organization.file_logo == null"
                    src="https://alppetro.co.id/dist/assets/images/default.jpg"
                    style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                    alt="abstract"
                  />

                  <img v-if="info_organization.file_bendera !== null" :src="fileURL + '/' + info_organization.file_bendera"
                    style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                    alt="abstract"
                    @error="replaceByDefault"
                  />
                  <img
                  v-if="info_organization.file_bendera == null"
                    src="https://alppetro.co.id/dist/assets/images/default.jpg"
                    style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                    alt="abstract"
                  />
                </b-row>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Info Pendirian" style="background-color:white;margin-top:0px;">
            <section class="d-flex justify-content-center align-items-center" v-if="loading2">
              <b-spinner type="border" size="lg"></b-spinner>
            </section>
            <b-row v-if="!loading2">
              <b-col cols="7">
                <b-row>
                  <b-col sm="4"
                    ><p style="font-weight: 600">Nama Notaris</p></b-col
                  >:<b-col sm="6">{{ founder_organization.namaNotaris}} </b-col>
                </b-row>
                <b-row class="d-flex align-items-center">
                  <b-col sm="4"
                    ><p style="font-weight: 600; margin:0px;">Nomor AKTA Pendirian</p></b-col
                  >:<b-col sm="6">{{ founder_organization.nomorAkta }} <b-button v-if="founder_organization.file_akta != null" @click="newTab(founder_organization.file_akta)" size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button></b-col>
                </b-row>
                <b-row>
                  <b-col sm="4"
                    ><p style="font-weight: 600">Tanggal AKTA Pendirian</p></b-col
                  >:<b-col sm="6">{{ founder_organization.tanggalAkta | moment("DD - MM - YYYY") }} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4"
                    ><p style="font-weight: 600">Tempat Pendirian</p></b-col
                  >:<b-col sm="6">{{ founder_organization.wilayahKabupatenKota.nama }} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4"
                    ><p style="font-weight: 600">Tanggal Pendirian</p></b-col
                  >:<b-col sm="6">{{ founder_organization.tanggalPendirian | moment("DD - MM - YYYY") }} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">
                      Nomor Surat Pendaftaran
                    </p>
                  </b-col>
                  :
                  <b-col sm="6">
                    {{ founder_organization.nomorSuratPendaftaran }} 
                    <b-button v-if="founder_organization.file_surat_pendaftaran != null" @click="newTab(founder_organization.file_surat_pendaftaran)" size="sm" variant="success">
                      <v-icon style="color:white;" small>
                        mdi-eye
                      </v-icon>
                      Lihat File Scan
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4"
                    ><p style="font-weight: 600">Tanggal Surat Pendaftaran</p></b-col
                  >:<b-col sm="6">{{ founder_organization.tanggalSuratPendaftaran | moment("DD - MM - YYYY") }} </b-col>
                </b-row>
                <!-- <b-row v-if="founder_organization.wilayahProvinsi.masterKesbangpolProv">
                  <b-col sm="4"
                    ><p style="font-weight: 600">Nama Kesbangpol Propinsi</p></b-col
                  >:<b-col sm="6">{{ founder_organization.wilayahProvinsi.masterKesbangpolProv.nama }} </b-col>
                </b-row>
                <b-row v-if="founder_organization.wilayahProvinsi.masterKesbangpolProv">
                  <b-col sm="4"
                    ><p style="font-weight: 600">Email Kesbangpol Propinsi</p></b-col
                  >:<b-col sm="6">{{ founder_organization.wilayahProvinsi.masterKesbangpolProv.email }} </b-col>
                </b-row>
                <b-row v-if="founder_organization.wilayahProvinsi.masterKesbangpolProv">
                  <b-col sm="4"
                    ><p style="font-weight: 600">Nomor HP Kesbangpol Propinsi</p></b-col
                  >:<b-col sm="6">{{ founder_organization.wilayahProvinsi.masterKesbangpolProv.nomorTelepon }} </b-col>
                </b-row> -->

                <b-row>
                  <b-col sm="4"
                    ><p style="font-weight: 600">Nama Kesbangpol Kabupaten/Kota</p></b-col
                  >:<b-col sm="6">{{ founder_organization.wilayahKabupatenKota.masterKesbangpolKab.nama }} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4"
                    ><p style="font-weight: 600">Email Kesbangpol Kabupaten/Kota</p></b-col
                  >:<b-col sm="6">{{ founder_organization.wilayahKabupatenKota.masterKesbangpolKab.email }} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4"
                    ><p style="font-weight: 600">Nomor HP Kesbangpol Kabupaten/Kota</p></b-col
                  >:<b-col sm="6">{{ founder_organization.wilayahKabupatenKota.masterKesbangpolKab.nomorTelepon }} </b-col>
                </b-row>
              </b-col>
              <b-col cols="5">
                <b-row>
                  <img v-if="info_organization.file_logo !== null" :src="fileURL + '/' + info_organization.file_logo"
                    style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                    alt="abstract"
                    @error="replaceByDefault"
                    />
                  <img
                    v-if="info_organization.file_logo == null"
                    src="https://alppetro.co.id/dist/assets/images/default.jpg"
                    style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                    alt="abstract"
                  />

                  <img v-if="info_organization.file_bendera !== null" :src="fileURL + '/' + info_organization.file_bendera"
                    style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                    alt="abstract"
                    @error="replaceByDefault"
                  />
                  <img
                  v-if="info_organization.file_bendera == null"
                    src="https://alppetro.co.id/dist/assets/images/default.jpg"
                    style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                    alt="abstract"
                  />
                </b-row>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Struktur Organisasi" style="background-color:white;margin-top:0px;">
            <section class="d-flex justify-content-center align-items-center" v-if="loading3">
              <b-spinner type="border" size="lg"></b-spinner>
            </section>
            <b-card-text class="pl-0 pr-0" v-if="!loading3">
              <div class="card-body pt-0 pb-3 pl-0 pr-0">
                <div class="tab-content">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <table
                      class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              "
                    >
                      <thead>
                        <tr class="text-left">
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                          <th style="width: 170px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama</th>
                          <th style="width: 200px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa Bakti</th>
                          <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. Identitas</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tanggal Lahir</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">KTP</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in structure_organization.pengurusInti">
                          <tr v-bind:key="i">
                            <td>
                              <img v-if="item.foto !== null" :src="fileURL + '/' + item.foto"
                                style="width: 64px;height:64px;"
                                alt="abstract"
                                @error="replaceByDefault"
                                />
                              <img
                                v-if="item.foto == null"
                                src="https://alppetro.co.id/dist/assets/images/default.jpg"
                                style="width: 64px;height:64px;"
                                alt="abstract"
                              />
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        "
                                >{{ item.nama_pengurus }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        "
                                >{{ item.periode_mulai | moment("DD/MM/YYYY") }} - {{  item.periode_selesai | moment("DD/MM/YYYY") }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                                >{{ item.masterJabatan.nama_jabatan }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-left
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                                >{{ item.identitas }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                                >{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        "
                                >{{ item.no_hp }}</span
                              >
                            </td>
                            <td>
                              
                                <b-button v-if="item.file_identitas != null" @click="newTab(item.file_identitas)" size="sm" variant="success">
                                  <v-icon style="color:white;" small>
                                    mdi-eye
                                  </v-icon>
                                  Lihat File Scan
                                </b-button>
                                
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                    
                  <!--end::Table-->
                  <div class="mt-5" v-if="structure_organization">
                    <b-row class="d-flex align-items-center">
                  <b-col sm="4"
                    ><p style="font-weight: 600; margin:0px;">SK Susunan Pengurus</p></b-col
                      >:<b-col sm="6"><b-button v-if="info_organization.file_sk_pengurus" @click="newTab(info_organization.file_sk_pengurus)" size="sm" variant="success"><v-icon style="color:white;" small>mdi-clipboard</v-icon>Lihat File Unggahan</b-button></b-col>
                    </b-row>

                    <b-row >
                      <b-col sm="4"
                        ><p style="font-weight: 600">Daftar Pendiri Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.nama_pengurus}};</span> 
                        </span> 
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4"
                        ><p style="font-weight: 600">NIK daftar Pendiri Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.identitas}};</span> 
                        </span> 
                      </b-col>
                    </b-row>

                    <b-row >
                      <b-col sm="4"
                        ><p style="font-weight: 600">Daftar Pembina Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.nama_pengurus}};</span> 
                        </span> 
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4"
                        ><p style="font-weight: 600">NIK daftar Pembina Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.identitas}};</span> 
                        </span> 
                      </b-col>
                    </b-row>

                    <b-row >
                      <b-col sm="4"
                        ><p style="font-weight: 600">Daftar Penasehat Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.nama_pengurus}};</span> 
                        </span> 
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4"
                        ><p style="font-weight: 600">NIK daftar Penasehat Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.identitas}};</span> 
                        </span> 
                      </b-col>
                    </b-row>

                    <b-row >
                      <b-col sm="4"
                        ><p style="font-weight: 600">Daftar Pelindung Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.nama_pengurus}};</span> 
                        </span> 
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4"
                        ><p style="font-weight: 600">NIK daftar Pelindung Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.identitas}};</span> 
                        </span> 
                      </b-col>
                    </b-row>

                  </div>
                </div>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Dokumen Organisasi" style="background-color:white;margin-top:0px;">
            <section class="d-flex justify-content-center align-items-center" v-if="loading4">
              <b-spinner type="border" size="lg"></b-spinner>
            </section>
            <div class="table-responsive" v-if="!loading4">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                <thead>
                  <tr class="text-left text-capitalize">
                    <th style="width: 20px" class="text-capitalize">No</th>
                    <th style="min-width: 50px;" class="text-capitalize">Deskripsi</th>
                    <th style="min-width: 200px" class="text-capitalize">Aksi</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, i) in document_organization">
                    <tr v-bind:key="i">
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{ i + 1 }}</span
                        >
                      </td>
                      <td style="max-width:50%;">
                        <span
                          class="
                    text-dark-75
                    text-left
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  "
                          >{{ item.nama_jenis_dokumen }}</span
                        >
                      </td>
                      <td class="d-flex justify-content-center">
                        
                        <!-- {{item.dokumenOrmas}} -->
                        <section v-if="item.dokumenOrmas">
                        <span v-for="(nn,ni) in item.dokumenOrmas.slice(0,1)" :key="ni">
                          <!-- <a :href="nn.path" download> <b-button size="sm" variant="primary" class="mr-3"><v-icon style="color:white;" small>mdi-download</v-icon>Unduh</b-button></a> -->
                          <b-button size="sm" variant="success" @click="newTabDoc(item.dokumenOrmas)"><v-icon style="color:white;" small>mdi-eye</v-icon><span>Lihat</span></b-button>
                        </span>
                        </section>
                        <b-button v-if="!item.dokumenOrmas" disabled size="sm" variant="success" @click="newTabDoc(item.dokumenOrmas)"><v-icon style="color:white;" small>mdi-eye</v-icon><span>Lihat</span></b-button>
                        <!-- <b-button v-if="!item.dokumenOrmas" disabled size="sm" variant="primary" class="ml-3" @click="download()"><v-icon style="color:white;" small >mdi-download</v-icon>Unduh</b-button> -->
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </b-tab>
          <b-tab title="Rekening" style="background-color:white;margin-top:0px;">
            <section class="d-flex justify-content-center align-items-center" v-if="loading5">
              <b-spinner type="border" size="lg"></b-spinner>
            </section>
            <div class="table-responsive" v-if="!loading5">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px">No</th>
                    <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                    <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, i) in bank_account_organization">
                    <tr v-bind:key="i">
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{ i + 1 }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="
                    text-dark-75
                    text-left
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{item.no_rekening}}</span
                        >
                      </td>
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    text-left
                    d-block
                    font-size-lg
                  "
                          >{{item.nama_bank}}</span
                        >
                      </td>
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    text-left
                    d-block
                    font-size-lg
                  "
                          >{{item.nama_pemilik_rekening}}</span
                        >
                      </td>
                      <td>
                        <span
                          class="
                    text-dark-75
                    text-left
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{item.id_jenis_rekening ? item.masterJenisRekening.nama : '-'}}</span
                        >
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
export default {
  name: "Informasi",
  data() {
    return {
      activeTab: 0,
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL,
      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,
      profilelist: [
        {
          no: "1",
          nama: "Ormas Test 1",
          noreg: "1021023010"
        }
      ],
      info_organization: {
        "id": null,
        "nama_ormas": "",
        "nama_singkat": "",
        "npwp": "",
        "m_kategori_ormas_id": null,
        "alamat_sekretariat": "",
        "no_skt": "",
        "tanggal_skt": "",
        "tanggal_berdiri": "",
        "email": "",
        "no_telp": "",
        "no_fax": "",
        "website": "",
        "status_ormas": null,
        "status_badan_hukum": null,
        "status_legalitas": null,
        "sys_user_id": null,
        "m_lokasi_sekretariat_id": null,
        "provinsi_sekretariat": "",
        "kabupaten_kota_sekretariat": "",
        "m_ciri_khusus_id": null,
        "bidangKegiatans": [
          {
            "id": null,
            "data_ormas_id": null,
            "keterangan": "",
            "m_jenis_ormas_id": null,
            "masterJenisOrmas": {
              "id": null,
              "nama_jenis_ormas": "",
              "terhapus": false
            }
          }
        ],
        "tujuanOrmas": [
          {
            "id": null,
            "data_ormas_id": null,
            "keterangan": ""
          }
        ],
        "masterProvinsi": {
          "id": null,
          "kode": "",
          "nama": "",
          "tipe": null,
          "latlong": null,
          "terhapus": false
        },
        "masterKabupatenKota": {
          "id": null,
          "kode": "",
          "nama": "",
          "tipe": null,
          "latlong": null,
          "terhapus": false
        },
        "masterCiriKhusus": {
          "id": null,
          "nama_ciri_khusus": "",
          "terhapus": 0
        }
      },
      founder_organization: {
        "id": null,
        "dataOrmasId": null,
        "cabangOrmasId": null,
        "nomorAkta": "",
        "tanggalAkta": "",
        "tempatPendirian": "",
        "nomorSuratPendaftaran": "",
        "tanggalSuratPendaftaran": "",
        "m_kesbangpol_id": null,
        wilayahProvinsi: {
          masterKesbangpolProv: {
            nama: '',
            email: '',
            noTelepon: ''
          }
        },
        wilayahKabupatenKota: {
          masterKesbangpolKab: {
            nama: '',
            email: '',
            noTelpon: ''
          }
        }
      },
      structure_organization: {
        "pengurusInti": [{
          "id": null,
          "data_ormas_id": null,
          "cabang_ormas_id": null,
          "nama_pengurus": "",
          "m_jenis_identitas_id": null,
          "identitas": "",
          "tempat_lahir": "",
          "tanggal_lahir": "",
          "alamat": "",
          "provinsi": "",
          "kabupaten_kota": "",
          "no_telp": "",
          "no_hp": "",
          "periode_mulai": "",
          "periode_selesai": "",
          "status_pengurus": null,
          "keterangan": "",
          "foto": "",
          "file_identitas": "",
          "m_kategori_pengurus_id": {},
          "m_jabatan_id": {},
          "masterKategoriPengurus": {
            "id": null,
            "nama_kategori_pengurus": "",
            "terhapus": null,
            "kode_kategori_pengurus": ""
          },
          "masterJabatan": {
            "id": null,
            "nama_jabatan": "",
            "terhapus": null,
            "kode_jabatan": ""
          }
        }],
        "listPengurus": [{
          "id": null,
          "data_ormas_id": null,
          "cabang_ormas_id": null,
          "nama_pengurus": "",
          "m_jenis_identitas_id": null,
          "identitas": "",
          "tempat_lahir": "",
          "tanggal_lahir": "",
          "alamat": "",
          "provinsi": "",
          "kabupaten_kota": "",
          "no_telp": "",
          "no_hp": "",
          "periode_mulai": "",
          "periode_selesai": "",
          "status_pengurus": null,
          "keterangan": "",
          "foto": "",
          "m_kategori_pengurus_id": {},
          "m_jabatan_id": {},
          "masterKategoriPengurus": {
            "id": null,
            "nama_kategori_pengurus": "",
            "terhapus": null,
            "kode_kategori_pengurus": ""
          },
          "masterJabatan": {
            "id": null,
            "nama_jabatan": "",
            "terhapus": null,
            "kode_jabatan": ""
          }
        }]
      },
      document_organization: [
          {
          "id": null,
          "nama_jenis_dokumen": "",
          "terhapus": null,
          "kode": ""
          }
        ],
      bank_account_organization: [
        {
        "id": null,
        "data_ormas_id": null,
        "cabang_id": null,
        "no_rekening": "",
        "nama_bank": "",
        "cabang_bank": "",
        "nama_pemilik_rekening": "",
        "id_jenis_rekening": ""
        },
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Data Organisasi" }]);
    this.getProfileInfo()
    //  this.getFounderOrganization()
  },
  watch:{
    activeTab(to){
      if(to == 0) this.getProfileInfo()
      else if (to == 1) this.getFounderOrganization()
      else if (to == 2) this.getStructureOrganization()
      else if (to == 3) this.getDocumentOrganization()
      else if (to == 4) this.getBankAccountOrganization()
    }
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = "/assets/images/default-thumbnail.jpg"
    },
    newTab(url){
      if(url.substring(0,4) == 'http') window.open(url);
      else window.open(this.fileURL + '/' + url);
    },
    getProfileInfo(){
      this.loading1 = true
      this.$store
        .dispatch("getProfileOrganization", null)
        .then((res) => {
          this.info_organization = res.data
          this.loading1 = false
        })
    },
    getFounderOrganization(){
      this.loading2 = true
      this.$store
        .dispatch("getFounderOrganization", this.info_organization.id)
        // .dispatch("getFounderOrganization", 4)
        .then((res) => {
          this.loading2 = false
          this.founder_organization = res.data
        })
    },
    getStructureOrganization(){
      this.loading3 = true
      this.$store
        .dispatch("getStructureOrganization", this.info_organization.id)
        // .dispatch("getStructureOrganization", 4)
        .then((res) => {
          this.structure_organization = res.data
          this.loading3 = false
        })
    },
    getDocumentOrganization(){
      this.loading4 = true
      this.$store
        .dispatch("getDocumentOrganization", this.info_organization.id)
        // .dispatch("getDocumentOrganization", 4)
        .then((res) => {
          this.document_organization = res.data
          this.loading4 = false
        })
    },
    getBankAccountOrganization(){
      this.loading5 = true
      this.$store
        .dispatch("getBankAccountOrganization", this.info_organization.id)
        // .dispatch("getBankAccountOrganization", 4)
        .then((res) => {
          this.bank_account_organization = res.data
          this.loading5 = false
        })
    },
    newTabDoc(url){
      if(url.length > 0){
        window.open(url[0].path);
      }else {
        Swal.fire({
            title: "Gagal Mendapatkan data",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          })
      }
    },
    download() {
      Swal.fire({
        title: "Download Tidak Berhasil",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      })
    },
    cetakPDF(){
      this.$store
        .dispatch("printPDFInfoOrg")
        .then((res) => {
          window.open(res.data.meta.urlPath , "_blank");
          })
    },
  },
  computed: {
    flagImage() {
      return process.env.BASE_URL + "media/svg/flags/226-united-states.svg";
    }
  }
};
</script>

<style>
.nav.nav-pills .nav-link {
  color: #053A69;
  font-weight: bold;
}
</style>
